import {StaticImage} from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"

import "../styles/team.css"

const team = () => {
    return (
        <Layout>
            <div className="teamHero pb-16">
                <div className="container mx-auto pt-24 pb-8 text-center w-1/2">
                    <h1 className="text-4xl md:text-6xl text-white font-normal text-shadow">Team</h1>
                </div>
            </div>

            {/* TEAM MEMBERS */}
            <div className="container mx-auto px-6 md:px-16 my-8 md:my-24">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>

                    <div className="rounded-md shadow overflow-hidden mx-5 mt-10">
                        <div>
                            <StaticImage
                                src="../images/team/team-member.jpg"
                                alt="Team Member"
                            />
                        </div>
                        <div className="p-5 text-center">
                            <h2 className="font-semibold text-2xl">Team Name</h2>
                            <h5 className="text-lg text-gray-500">Member Position</h5>
                            <p>
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual form.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default team
